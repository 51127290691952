import classNames from 'classnames'
import PropTypes from 'prop-types'
import React from 'react'
import {
  FaRegCalendarAlt, FaRegClock, FaPhoneAlt, FaEnvelope, FaLocationArrow, FaInstagram, FaFacebook
} from 'react-icons/fa'
import Col from 'react-bootstrap/Col'
import Row from 'react-bootstrap/Row'

import Section from '../Section'

const IconWrapper = ({
  className, icon: Icon, children, isLink, ...props
}) => {
  const content = (
    <>
      <Icon />
      <span className="pl-3">{children}</span>
    </>
  )

  return (
    <div className={classNames('d-flex justify-content-center justify-content-lg-start align-items-center pt-2', className)}>
      {isLink ? (
        <a {...props}>
          {content}
        </a>
      ) : content}
    </div>
  )
}

IconWrapper.defaultProps = {
  className: undefined,
  isLink: false
}

IconWrapper.propTypes = {
  className: PropTypes.string,
  icon: PropTypes.node.isRequired,
  children: PropTypes.node.isRequired,
  isLink: PropTypes.bool
}

const mapsUrl = 'https://www.google.com/maps/place/VINICA+-+cave+%C3%A0+vin/@45.7693989,4.8312715,19z/data=!3m1!4b1!4m5!3m4!1s0x47f4eb2ebba80cb7:0x277bd3309eb3e3bd!8m2!3d45.7693989!4d4.8318187'

const Footer = () => (
  <Section id="contact" className="py-5" fluid>
    <Row className="py-5 text-center text-lg-left">
      <Col xs={12} lg={4} className="pb-5 pb-lg-0">
        <h4 className="font-bitter text-pink">Vinica</h4>
        <div className="d-flex flex-wrap justify-content-center justify-content-lg-start">
          <IconWrapper icon={FaRegCalendarAlt}>
            Lundi - Mercredi
          </IconWrapper>
          <IconWrapper icon={FaRegClock} className="pl-3">
            11h00-19h30
          </IconWrapper>
        </div>
        <div className="d-flex flex-wrap justify-content-center justify-content-lg-start">
          <IconWrapper icon={FaRegCalendarAlt}>
            Jeudi - Samedi
          </IconWrapper>
          <IconWrapper icon={FaRegClock} className="pl-4">
            11h00-20h00
          </IconWrapper>
        </div>
      </Col>
      <Col xs={12} lg={4} className="pb-5 pb-lg-0">
        <h4 className="font-bitter text-pink">Contact</h4>
        <div>
          <IconWrapper icon={FaPhoneAlt} href="tel:+330478588629" isLink>
            +33 04 78 58 86 29
          </IconWrapper>
          <IconWrapper icon={FaEnvelope} href="mailto:info@vinica-lyon.fr" isLink>
            info@vinica-lyon.fr
          </IconWrapper>
          <IconWrapper icon={FaLocationArrow} href={mapsUrl} target="_blank" rel="noopener noreferrer" isLink>
            120 Montée de la Grande-Côte, Lyon
          </IconWrapper>
        </div>
      </Col>
      <Col xs={12} lg={4}>
        <h4 className="font-bitter text-pink">Follow us</h4>
        <div>
          <IconWrapper icon={FaInstagram} href="https://www.instagram.com/vinica.lyon/" target="_blank" rel="noopener noreferrer" isLink>
            Instagram
          </IconWrapper>
          <IconWrapper icon={FaFacebook} href="https://www.facebook.com/vinica.lyon/" target="_blank" rel="noopener noreferrer" isLink>
            Facebook
          </IconWrapper>
        </div>
      </Col>
    </Row>
  </Section>
)

export default Footer
