/**
 * SEO component that queries for data with
 *  Gatsby's useStaticQuery React hook
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */

import * as React from 'react'
import PropTypes from 'prop-types'
import { Helmet } from 'react-helmet'
import { useStaticQuery, graphql } from 'gatsby'

const Seo = ({
  description, lang, meta, title, keywords
}) => {
  const { site: { siteMetadata } } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
            description
            author
            keywords
            siteUrl
          }
        }
      }
    `
  )

  const metaAttributes = {
    description: description || siteMetadata.description,
    keywords: keywords || siteMetadata.keywords,
    url: siteMetadata.siteUrl,
    siteName: siteMetadata.siteName
  }

  return (
    <Helmet
      htmlAttributes={{
        lang
      }}
      title={title}
      titleTemplate={siteMetadata.title}
      meta={[
        {
          name: 'description',
          content: metaAttributes.description
        },
        {
          property: 'og:title',
          content: title
        },
        {
          property: 'og:description',
          content: metaAttributes.description
        },
        {
          name: 'keywords',
          content: metaAttributes.keywords.join(', ')
        },
        {
          property: 'og:site_name',
          content: metaAttributes.siteName
        },
        {
          property: 'og:url',
          content: metaAttributes.url
        },
        {
          property: 'og:locale',
          content: 'fr_FR'
        },
        {
          property: 'og:type',
          content: 'website'
        },
        {
          name: 'twitter:card',
          content: 'summary'
        },
        {
          name: 'twitter:creator',
          content: siteMetadata.author
        },
        {
          name: 'twitter:title',
          content: title
        },
        {
          name: 'twitter:description',
          content: metaAttributes.description
        }
      ].concat(meta)}
    />
  )
}

Seo.defaultProps = {
  lang: 'fr',
  meta: [],
  keywords: [],
  description: ''
}

Seo.propTypes = {
  description: PropTypes.string,
  lang: PropTypes.string,
  meta: PropTypes.arrayOf(PropTypes.object), // eslint-disable-line react/forbid-prop-types
  keywords: PropTypes.arrayOf(PropTypes.string),
  title: PropTypes.string.isRequired
}

export default Seo
