import classNames from 'classnames'
import PropTypes from 'prop-types'
import React from 'react'
import Container from 'react-bootstrap/Container'

const Section = ({ children, className, ...props }) => (
  <Container className={classNames('px-lg-5', className)} {...props}>
    {children}
  </Container>
)

Section.defaultProps = {
  className: undefined
}

Section.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string
}

export default Section
