import classNames from 'classnames'
import React from 'react'
import Navbar from 'react-bootstrap/Navbar'
import Nav from 'react-bootstrap/Nav'
import Image from 'react-bootstrap/Image'
import { FaBars } from 'react-icons/fa'
import Section from '../Section'
import Logo from './vinica-logo.png'

import * as styles from './index.module.scss'

const Header = () => (
  <header>
    <Navbar
      expand="lg"
      bg="light"
      fixed="top"
      className={classNames(styles.header, 'bg-light px-5 vw-100')}
      collapseOnSelect
    >
      <Section fluid>
        <Navbar.Brand href="/">
          <Image src={Logo} className={styles.logo} alt="logo" fluid />
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="mobile-nav" className="border-0">
          <FaBars className={styles.burgerIcon} />
        </Navbar.Toggle>
        <Navbar.Collapse
          id="mobile-nav"
        >
          <Nav className="ml-auto text-center">
            <Nav.Item>
              <Nav.Link href="#accueil">
                Accueil
              </Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link href="#la-cave">
                La Cave
              </Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link href="#les-vins">
                Vins et Spiritueux
              </Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link href="#degustation">
                Dégustation
              </Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link href="#contact">
                Contact
              </Nav.Link>
            </Nav.Item>
          </Nav>
        </Navbar.Collapse>
      </Section>
    </Navbar>
  </header>
)

export default Header
